import React from 'react'

import IntroText from 'components/IntroText'
import Layout from 'components/Layout'
import Seo from 'components/Seo'

import { useLocalization } from 'gatsby-theme-i18n'

const Page = () => {
  const { locale } = useLocalization()

  let content = {
    en: '404: not found',
    zh: '404: 没有找到页面',
  }
  content = content[locale]
  const seoTitle = content
  let seoDescription = {
    en: 'Page not found.',
    zh: '没有找到页面',
  }
  seoDescription = seoDescription[locale]

  return (
    <Layout>
      <div>
        <Seo pageTitle={seoTitle} description={seoDescription} />
        <div style={{ height: '150px', backgroundColor: `rgb(54, 80, 114)` }} />
        <div>
          <div className='wrapper pad-sides'>
            <div className='inner'>
              <div className='wrapper pad-sides'>
                <IntroText content={<h1>{content}</h1>} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
